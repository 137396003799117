/*
 * Custom buttons for the IWV homepage
 *
 * These buttons appear below all other buttons when the
 * given jurisdiction is selected on the IWV landing page.
 * (See /front-door/src/components/ActionOptions.tsx)
 *
 * It's recommmended that the URL be defined in jurisdictionURLs,
 * instead of directly defining it here.
 * ./custom-buttons.test.ts ensures optional urls are defined for
 * the given jurisdiction.
 */

import { Jurisdiction } from '../../data/jurisdictions';
import { JurisdictionButton } from '../../utils';
// import { jurisdictionURLs } from './urls';

export const getCustomButtons = (
  jurisdiction: Jurisdiction
): JurisdictionButton[] => {
  switch (jurisdiction) {
    // Example:
    // case 'OH': {
    //   return [
    //     {
    //       analyticsKey: 'sample_ballot',
    //       kind: 'sample-ballot',
    //       name: {
    //         en: 'View your sample ballot',
    //         es: 'Ver una muestra de tu boleta',
    //       },
    //       url: jurisdictionURLs[jurisdiction].sampleBallotURL!,
    //     },
    //   ];
    // }
    default:
      return [];
  }
};
