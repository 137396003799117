import * as qs from 'query-string';
import * as React from 'react';
import { Location } from 'react-router-dom';

import {
  AnalyticsService,
  Jurisdiction,
  Option,
  ActiveLocale,
} from '@dnc/baseline';

import { queryToUtmFields } from '../services/utils';

const AnalyticsContext = React.createContext<AnalyticsService | null>(null);

/**
 * Returns the globally-provided {@link AnalyticsService} from
 * {@link AnalyticsProvider}.
 *
 * Throws an Error if no AnalyticsProvider was set.
 */
export function useAnalytics(): AnalyticsService {
  const analytics = React.useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error('useAnalytics called outside of an AnalyticsProvider');
  }

  return analytics;
}

/**
 * Provider that updates an {@link AnalyticsService} instance with the latest
 * language, jurisdiction, and routes, and also provides it to child components
 * through a React {@link Context}.
 *
 * @see useAnalytics
 */
export const AnalyticsProvider: React.FC<{
  analytics: AnalyticsService;
  location: Location;
  locale: ActiveLocale;
  jurisdiction: Option<Jurisdiction>;
  children?: React.ReactNode;
}> = ({ analytics, children, location, locale, jurisdiction }) => {
  const analyticsRef = React.useRef(analytics);

  React.useLayoutEffect(() => {
    analyticsRef.current = analytics;
  });

  React.useLayoutEffect(() => {
    analyticsRef.current.setLocale(locale);
  }, [locale]);

  React.useLayoutEffect(() => {
    analyticsRef.current.setJurisdiction(jurisdiction);
  }, [jurisdiction]);

  React.useLayoutEffect(
    () => {
      analyticsRef.current.routeChanged(location, locale, jurisdiction);

      const utmFields = queryToUtmFields(qs.parse(location.search));

      if (utmFields) {
        analyticsRef.current.setUtmFields(utmFields);
      }
    },
    // We only want to send routeChanged when pathname or search changed, not
    // any other location changes.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, location.search]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsContextProviderForTest = AnalyticsContext.Provider;
