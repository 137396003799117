import { QueryClient } from '@tanstack/react-query';

import {
  AnalyticsService,
  Configuration,
  ElectionsProvider,
  ElectionsService,
  FormService,
  FormServiceImpl,
  GoogleAnalyticsAdapter,
  JurisdictionProvider,
  JurisdictionService,
  SentryService,
  JurisdictionSettingsProvider,
  JurisdictionSettingsService,
  VISProvider,
  VISService,
  VoterEducationProvider,
  VoterEducationService,
} from '@dnc/baseline';

import { PollingPlaceService } from './polling-place-service';

export class Services
  implements
    ElectionsProvider,
    JurisdictionProvider,
    JurisdictionSettingsProvider,
    VISProvider,
    VoterEducationProvider
{
  readonly configuration: Configuration;

  readonly queryClient: QueryClient;

  readonly analytics: AnalyticsService;

  readonly jurisdiction: JurisdictionService;
  readonly settings: JurisdictionSettingsService;

  readonly elections: ElectionsService;

  readonly voterEducation: VoterEducationService;

  readonly vis: VISService;
  readonly pollingPlace: PollingPlaceService;
  readonly form: FormService;

  constructor() {
    switch (process.env.CONFIGURATION) {
      case Configuration.development:
        this.configuration = Configuration.development;
        break;
      case Configuration.production:
        this.configuration = Configuration.production;
        break;
      default:
        throw `Unexpected configuration: ${process.env.CONFIGURATION}`;
    }

    this.queryClient = new QueryClient();

    this.analytics = new AnalyticsService(new GoogleAnalyticsAdapter());

    this.voterEducation = new VoterEducationService({
      queryClient: this.queryClient,
    });

    this.elections = new ElectionsService({
      voterEducationService: this.voterEducation,
    });

    this.vis = new VISService(this.analytics);

    this.jurisdiction = new JurisdictionService({
      geoip: this.vis,
      queryClient: this.queryClient,
    });

    new SentryService(process.env.SENTRY_DSN || '');

    this.settings = new JurisdictionSettingsService();

    this.pollingPlace = new PollingPlaceService({
      electionsService: this.elections,
      visService: this.vis,
      queryClient: this.queryClient,
    });

    this.form = new FormServiceImpl(this.configuration);
  }
}
