import * as qs from 'query-string';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { Link, Outlet, useLocation } from 'react-router-dom';

import {
  AnalyticsService,
  ActiveLocale,
  GoogleAnalyticsAdapter,
  isActiveLocale,
} from '@dnc/baseline';

import {
  DEFAULT_LOCALE,
  DEFAULT_RICH_TEXT_ELEMENTS,
  locales,
} from '../../i18n/i18n-config';

import { UrlHelper } from '../../services/url-helper';
import { AnalyticsProvider } from '../AnalyticsProvider';
import { LocaleContext } from '../shared/LocaleContext';

import { BannerLogo } from './banner-logo';

export const PageWrapper: React.FC<{}> = () => {
  const location = useLocation();
  const urlHelper = UrlHelper.fromLocation(location);

  const params = qs.parse(location.search);
  const locale: ActiveLocale = isActiveLocale(params['lang'])
    ? params['lang']
    : 'en';

  const analytics = React.useMemo(
    () => new AnalyticsService(new GoogleAnalyticsAdapter()),
    []
  );

  return (
    <LocaleContext.Provider value={locale}>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={locales[locale].messages}
        defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
      >
        <AnalyticsProvider
          analytics={analytics}
          location={location}
          locale={locale}
          // We don’t update the jurisdiction for audit pages
          jurisdiction={undefined}
        >
          <div className="page-wrap">
            <header className="header">
              <div className="header__inner header__inner--audit">
                <Link
                  role="banner"
                  to={urlHelper.homeURL(undefined)}
                  aria-label="IWillVote.com"
                  tabIndex={0}
                >
                  <BannerLogo locale={locale} />
                </Link>
              </div>
            </header>
            <div className="content-wrap">
              <section className="locate__container locate__container--audit">
                <Outlet />
              </section>
            </div>
          </div>
        </AnalyticsProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
