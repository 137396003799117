import * as React from 'react';

import { ActiveLocale, ActiveLocaleToInLanguage } from '../baseline/utils';

type LocaleSelectorProps = {
  displayLocale: ActiveLocale;
  onClick: () => void;
};

export const LocaleSelector: React.FC<LocaleSelectorProps> = ({
  displayLocale,
  onClick,
}) => {
  const inLanguageText = ActiveLocaleToInLanguage[displayLocale];

  return (
    <button
      className="locale-selector"
      onClick={onClick}
      type="button"
      lang={displayLocale}
    >
      {inLanguageText}
    </button>
  );
};
