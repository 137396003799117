import { createClient } from 'contentful';
import moment from 'moment';

import {
  ProductionJurisdictionConfigEntries,
  TestingJurisdictionConfigEntries,
} from '../contentful/jurisdiction-entries';
import {
  ProductionTerritoryConfigEntries,
  TestingTerritoryConfigEntries,
} from '../contentful/territory-entries';
import {
  ContentfulDate,
  ContentfulDateMappingType,
  ContentfulDates,
  ContentfulLinkedDate,
  ContentfulLinkedElection,
  JurisdictionLocateMessage,
  LinkedAlertFields,
  ImportantDates,
  SitewideAlertsObject,
  JurisdictionRegistrationConfig,
  JurisdictionConfig,
  ContentfulRichTextObject,
  ExternalContentfulUrls,
  isContentfulUrl,
  ContentfulJurisdictionConfig,
  JurisdictionVepConfig,
  JurisdictionPollingLookupConfig,
  JurisdictionBallotRequestConfig,
  ContentfulTerritoryConfig,
  TerritoryConfig,
  NationalBooleanConfig,
  NationalLocalesConfig,
  JurisdictionBooleanConfig,
  ContentfulSitewideAlertsObject,
  isLinkedDate,
  ContentfulLinkedVbmNote,
  JurisdictionLocaleSupport,
} from '../contentful/types';
import { ElectionInfo } from '../data/election-types';
import { Territory, Jurisdiction, State } from '../data/jurisdictions';
import {
  ActiveLanguageToLocale,
  ActiveLocale,
  ActiveLocalizedString,
  DEFAULT_LOCALES,
  DefaultLanguageToLocale,
  isActiveLanguage,
} from '../utils';
import { Option } from '../utils/option';

/*
 Contentful is a content management service
 Contentful will be the home for voter education
 information, replacing the information available
 in @dnc/baseline. This will allow for faster
 updates/deployment without engineer involvement.
*/

export const parseDateString = (electionDate: string): moment.Moment =>
  moment(electionDate);

export const parseLocalizedDate = (
  dateObject: Option<ContentfulDate>
): Option<moment.Moment> => {
  /* Contentful Dates are mapped to date strings.
    Important Dates and Deadlines are localized
    content (ex: { en: "YYYY-MM-DD"}), while Election
    Dates are not localized (ex: "YYYY-MM-DD").
    We reuse parseDateString() to handle the date
    string itself, while parseLocalizedDate() handles
    parsing the date from localized content.
  */
  if (dateObject?.en) {
    return parseDateString(dateObject.en);
  }
  return undefined;
};

/**
 * Either a short or long note (likely for a date).
 *
 * If provided, will have both "en" and "es" fields based on checking in
 * {@link parseContentfulNote}.
 */
export type ParsedNote =
  | {
      type: 'short';
      note: ActiveLocalizedString;
    }
  | {
      type: 'long';
      note: ContentfulRichTextObject;
    };

/**
 * @returns A {@link ParsedDate} if there are both EN and ES translations for
 * the given note objects. Prioritizes the short note over the long note.
 */
export const parseContentfulNote = (
  note: Option<ActiveLocalizedString>,
  longNote: Option<ContentfulRichTextObject>
): Option<ParsedNote> => {
  // We prioritize short note over long note.
  if (note?.en?.length && note?.es?.length) {
    return { type: 'short', note };
  } else if (longNote?.en?.content.length && longNote?.es?.content.length) {
    return { type: 'long', note: longNote };
  }
  return undefined;
};

export const parseElectionDates = (
  dates: ContentfulDates,
  earlyVoteBoolean: Option<boolean>
): ImportantDates => ({
  earlyVotingStartBy: parseLocalizedDate(
    dates.earlyVotingStartBy?.electionDate
  ),
  earlyVotingStartByNote: parseContentfulNote(
    dates.earlyVotingStartBy?.note,
    dates.earlyVotingStartBy?.longNote
  ),
  earlyVotingStartIsSameStatewide: earlyVoteBoolean,
  inPersonAbsenteeStartBy: parseLocalizedDate(
    dates.inPersonAbsenteeStartBy?.electionDate
  ),
  inPersonAbsenteeStartByNote: parseContentfulNote(
    dates.inPersonAbsenteeStartBy?.note,
    dates.inPersonAbsenteeStartBy?.longNote
  ),
  electionDay: parseLocalizedDate(dates.electionDay?.electionDate),
  electionDayStatewideHours: parseContentfulNote(
    dates.electionDayStatewideHours?.note,
    dates.electionDayStatewideHours?.longNote
  ),
  ballotRequestBy: parseLocalizedDate(dates.requestBallotBy?.electionDate),
  ballotRequestByNote: parseContentfulNote(
    dates.requestBallotBy?.note,
    dates.requestBallotBy?.longNote
  ),
  ballotDropoffBy: parseLocalizedDate(dates.dropoffBallotBy?.electionDate),
  ballotDropoffByNote: parseContentfulNote(
    dates.dropoffBallotBy?.note,
    dates.dropoffBallotBy?.longNote
  ),
  ballotPostmarkBy: parseLocalizedDate(dates.postmarkBallotBy?.electionDate),
  ballotPostmarkByNote: parseContentfulNote(
    dates.postmarkBallotBy?.note,
    dates.postmarkBallotBy?.longNote
  ),
  ballotReceiveBy: parseLocalizedDate(dates.receiveBallotBy?.electionDate),
  ballotReceiveByNote: parseContentfulNote(
    dates.receiveBallotBy?.note,
    dates.receiveBallotBy?.longNote
  ),
  registerOnlineBy: parseLocalizedDate(dates.registerOnlineBy?.electionDate),
  registerOnlineByNote: parseContentfulNote(
    dates.registerOnlineBy?.note,
    dates.registerOnlineBy?.longNote
  ),
  registerInPersonBy: parseLocalizedDate(
    dates.registerInPersonBy?.electionDate
  ),
  registerInPersonByNote: parseContentfulNote(
    dates.registerInPersonBy?.note,
    dates.registerInPersonBy?.longNote
  ),
  registerSameDayNote: parseContentfulNote(
    dates.registerSameDay?.note,
    dates.registerSameDay?.longNote
  ),
  registerReceiveBy: parseLocalizedDate(dates.registerReceiveBy?.electionDate),
  registerReceiveByNote: parseContentfulNote(
    dates.registerReceiveBy?.note,
    dates.registerReceiveBy?.longNote
  ),
  registerPostmarkBy: parseLocalizedDate(
    dates.registerPostmarkBy?.electionDate
  ),
  registerPostmarkByNote: parseContentfulNote(
    dates.registerPostmarkBy?.note,
    dates.registerPostmarkBy?.longNote
  ),
  voteByMailNote: dates.voteByMailNote,
});

export const mapDatesArrayToImportantDatesObject = (
  datesDeadlinesArray: Option<
    Array<ContentfulLinkedDate | ContentfulLinkedVbmNote>
  >
): ContentfulDates => {
  /*
  In Contentful, Important Dates and Deadlines are returned as
  an array of objects.

  In IWV, the ImportantDates object represents a consolidation of
  the Important Dates and Deadlines array. This method remaps the
  array of objects from Contentful to the Important Dates object.
  */
  if (!datesDeadlinesArray) {
    return {};
  }
  const remappedDatesArray = datesDeadlinesArray.map((element) => {
    const nameToTranspose = isLinkedDate(element)
      ? element.fields.dateType.en
      : 'Vote by Mail Note';
    const fieldsToTranspose = isLinkedDate(element)
      ? element.fields
      : element.fields.noteCopy;
    return { [ContentfulDateMapping[nameToTranspose]]: fieldsToTranspose };
  });
  const datesObject: ContentfulDates = Object.assign({}, ...remappedDatesArray);
  return datesObject;
};

export const parseLinkedElection = (
  linkedElection: ContentfulLinkedElection,
  todayDate: moment.Moment
): ElectionInfo | undefined => {
  if (!linkedElection.fields) {
    return undefined;
  }

  const parsedDate = parseLocalizedDate(linkedElection.fields.electionDate);
  if (!parsedDate) {
    return undefined;
  }

  const electionInfo: ElectionInfo = {
    internalName: linkedElection.fields.internalName.en,
    electionType: linkedElection.fields.electionType.en,
    electionDay: parsedDate,
    earlyVoting: {
      allowed: linkedElection.fields.isEarlyVotingAllowed.en,
    },
  };

  if (
    linkedElection.fields.earlyVotingStartDate &&
    linkedElection.fields.earlyVotingEndDate
  ) {
    electionInfo.earlyVoting.startDate = parseDateString(
      linkedElection.fields.earlyVotingStartDate.en
    );
    electionInfo.earlyVoting.endDate = parseDateString(
      linkedElection.fields.earlyVotingEndDate.en
    );
  }

  if (moment(electionInfo.electionDay).isSameOrAfter(todayDate, 'day')) {
    return electionInfo;
  } else {
    return undefined;
  }
};

const ContentfulDateMapping: ContentfulDateMappingType = {
  'Early Vote Start By': 'earlyVotingStartBy',
  'Election Day': 'electionDay',
  'Election Day Statewide Hours': 'electionDayStatewideHours',
  'In Person Absentee Start By': 'inPersonAbsenteeStartBy',
  'Request Ballot By': 'requestBallotBy',
  'Dropoff Ballot By': 'dropoffBallotBy',
  'Postmark Ballot By': 'postmarkBallotBy',
  'Receive Ballot By': 'receiveBallotBy',
  'Register Online By': 'registerOnlineBy',
  'Register In Person By': 'registerInPersonBy',
  'Register Same Day': 'registerSameDay',
  'Receive Register By': 'registerReceiveBy',
  'Register Postmark By': 'registerPostmarkBy',
  'Vote by Mail Note': 'voteByMailNote',
};

/*
 The following methods are responsible for
 collecting Urls from Contentful.
 
  `getEntryUrls`
  - retrieves all urls from Contentful entries
  `getRichTextUrls` 
  - returns an object with localized external urls found in rich text
  `extractUrlsFromRichText` 
  - extracts all urls from richText content nodes
  `getExternalUrls` 
  - filters the results from `extractUrlsFromRichText`to prioritize non-IWV/external urls.
*/

export const extractUrlsFromRichText = (node: any): Array<string> => {
  if (isContentfulUrl(node)) {
    return [node.data.uri];
  } else if (!node.content) {
    return [];
  } else {
    return node.content.flatMap((element: any) =>
      extractUrlsFromRichText(element)
    );
  }
};

export const getExternalUrls = (
  richTextUrlArray: Option<Array<string>>,
  isSpanishContent: boolean = false
) => {
  if (!richTextUrlArray) {
    return [];
  } else {
    const nonIWillVoteLinks = richTextUrlArray.filter(
      (urlElement) => !urlElement.includes('iwillvote.com')
    );
    if (isSpanishContent) {
      return nonIWillVoteLinks.filter(
        (urlElement) => !urlElement.includes('voyavotar.com')
      );
    }
    return nonIWillVoteLinks;
  }
};

export const getRichTextUrls = (
  richTextBlock: Option<ContentfulRichTextObject>
) => {
  if (richTextBlock) {
    const richTextUrlsEn = extractUrlsFromRichText(richTextBlock.en);
    const richTextUrlsEs = extractUrlsFromRichText(richTextBlock.es);
    const externalEn = getExternalUrls(richTextUrlsEn);
    const isSpanishContent = true;
    const externalEs = getExternalUrls(richTextUrlsEs, isSpanishContent);
    return {
      en: externalEn,
      es: externalEs,
    };
  }
};

export const getAllRichTextObjects = (
  entry: Option<ContentfulJurisdictionConfig>
) => {
  if (!entry) {
    return undefined;
  }
  const datesAndVbmNoteArray = entry.importantDatesAndDeadlines?.en;
  const datesAndVbmObject =
    mapDatesArrayToImportantDatesObject(datesAndVbmNoteArray);
  return {
    vepRegRichText: entry.vepConfig.en.fields.registrationRequirementsCopy,
    vepIdRichText: entry.vepConfig.en.fields.idRequirementsCopy,
    vepVBMRichText: datesAndVbmObject.voteByMailNote,
    vepHowToVoteRichText: entry.vepConfig.en.fields.howToCompleteBallotCopy,
    locateRichText:
      entry.pollingLocationLookupConfig.en.fields.locateErrorMessageCopy,
    alertRichText: entry.jurisdictionAlertsConfig?.en.fields.alertMessage,
    absenteeExcuseRichText:
      entry.pollingLocationLookupConfig.en.fields.earlyAbsenteeExcuseCopy,
  };
};

export const getEntryUrls = (entry: Option<ContentfulJurisdictionConfig>) => {
  const regConfig = entry?.voterRegistrationConfig?.en.fields;
  const ballotRequestConfig = entry?.ballotRequestConfig?.en.fields;
  const pollingConfig = entry?.pollingLocationLookupConfig.en.fields;
  const richTextObj = getAllRichTextObjects(entry);
  const vepRegRichText = getRichTextUrls(richTextObj?.vepRegRichText);
  const vepIdRichText = getRichTextUrls(richTextObj?.vepIdRichText);
  const vepVBMRichText = getRichTextUrls(richTextObj?.vepVBMRichText);
  const locateRichText = getRichTextUrls(richTextObj?.locateRichText);
  const alertRichText = getRichTextUrls(richTextObj?.alertRichText);
  const absenteeExcuseRichText = getRichTextUrls(
    richTextObj?.absenteeExcuseRichText
  );
  const vepCompleteBallotRichText = getRichTextUrls(
    richTextObj?.vepHowToVoteRichText
  );
  return {
    onlineReg: regConfig?.onlineinPersonRegistrationUrl,
    alternateReg: regConfig?.alternateRegistrationUrl,
    mailInReg: regConfig?.mailInpaperRegistrationUrl,
    ballotRequest: ballotRequestConfig?.sosBallotRequestUrl,
    locationLookup: pollingConfig?.sosLocationLookupUrl,
    vepRegRichText,
    vepIdRichText,
    vepVBMRichText,
    vepCompleteBallotRichText,
    locateRichText,
    alertRichText,
    absenteeExcuseRichText,
  };
};

export const getContentfulUrls = async (
  stateCode: State
): Promise<ExternalContentfulUrls> => {
  const client = createClient({
    accessToken: 'p8TjTbAleq7qE9qpj6CtyDE_FvLqcVgU_zBNBtHAIqA',
    host: 'cdn.contentful.com',
    space: 'ntimk97nqy25',
  });
  const entryId = ProductionJurisdictionConfigEntries[stateCode];
  const entryResponse = await client.withAllLocales.getEntry(entryId, {
    include: 3,
  });
  const entry = entryResponse.fields as ContentfulJurisdictionConfig;
  const jurisdictionURLs = getEntryUrls(entry);
  return jurisdictionURLs;
};

export class ContentfulService {
  private readonly previewUrl: string;

  private readonly displayUrl: string;

  private readonly previewToken: string;

  private readonly displayToken: string;

  private readonly spaceId: string;

  constructor(
    previewUrl = process.env.CONTENTFUL_PREVIEW_HOST as string,
    displayUrl = process.env.CONTENTFUL_DISPLAY_HOST as string,
    previewToken = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN as string,
    displayToken = process.env.CONTENTFUL_DISPLAY_ACCESS_TOKEN as string,
    spaceId = process.env.CONTENTFUL_SPACE_ID as string
  ) {
    this.previewUrl = previewUrl;
    this.displayUrl = displayUrl;
    this.previewToken = previewToken;
    this.displayToken = displayToken;
    this.spaceId = spaceId;
  }

  stateDeadlinesToImportantDate(
    entry: Option<ContentfulJurisdictionConfig>
  ): ImportantDates {
    const datesAndVbmNoteArray = entry?.importantDatesAndDeadlines?.en ?? [];
    const earlyVoteBoolean = entry?.earlyVotingStartIsSameStatewide.en;
    const datesObject =
      mapDatesArrayToImportantDatesObject(datesAndVbmNoteArray);
    return parseElectionDates(datesObject, earlyVoteBoolean);
  }

  territoryDeadlinesToImportantDate(
    entry: Option<ContentfulTerritoryConfig>
  ): ImportantDates {
    const datesArray = entry?.importantDatesAndDeadlines?.en ?? [];
    // Early Vote Boolean is hard-coded to false, because
    // VEP text 'Starts Statewide' does not apply to territories
    const earlyVoteBoolean = false;
    const datesObject = mapDatesArrayToImportantDatesObject(datesArray);
    return parseElectionDates(datesObject, earlyVoteBoolean);
  }

  electionToElectionInfo(
    entry: Option<ContentfulJurisdictionConfig>,
    today: moment.Moment
  ): Option<ElectionInfo> {
    const election = entry?.activeElection?.en;
    if (!election) {
      return undefined;
    }
    return parseLinkedElection(election, today);
  }

  extractAlert(
    entry: Option<ContentfulJurisdictionConfig>
  ): Array<LinkedAlertFields> {
    const alert = entry?.jurisdictionAlertsConfig?.en;
    if (!alert) {
      return [] as Array<LinkedAlertFields>;
    }
    return [alert.fields];
  }

  getLocateMessage(
    entry: Option<ContentfulJurisdictionConfig>,
    todayDate: moment.Moment
  ): Option<JurisdictionLocateMessage> {
    if (!entry) {
      return undefined;
    }
    const lookupConfig = entry.pollingLocationLookupConfig.en.fields;
    if (
      lookupConfig.locateErrorStartDate &&
      lookupConfig.locateErrorEndDate &&
      lookupConfig.locateErrorHeadlineCopy &&
      lookupConfig.locateErrorMessageCopy
    ) {
      // we only want to return active locate error messages
      const errorStart = parseLocalizedDate(lookupConfig.locateErrorStartDate);
      const errorEnd = parseLocalizedDate(lookupConfig.locateErrorEndDate);
      if (
        moment(errorStart).isSameOrBefore(todayDate, 'day') &&
        moment(errorEnd).isSameOrAfter(todayDate, 'day')
      ) {
        return {
          headline: lookupConfig.locateErrorHeadlineCopy,
          errorMessage: lookupConfig.locateErrorMessageCopy,
          startDate: lookupConfig.locateErrorStartDate,
          endDate: lookupConfig.locateErrorEndDate,
        };
      }
    }
    /* 
      if the entry exists, but:
      - there is no locate error message
      - or the locate error message has expired
      we return undefined
    */
    return undefined;
  }

  extractPollingLocationLookupConfig(
    entry: Option<ContentfulJurisdictionConfig>,
    today: moment.Moment,
    election: Option<ElectionInfo>
  ): JurisdictionPollingLookupConfig {
    if (!entry) {
      return {
        displayVotingLocationLookup: false,
        displayEarlyAbsenteeExcuse: false,
        lookupExperience: 'none',
      };
    }
    const pollingConfig = entry.pollingLocationLookupConfig.en.fields;
    const locateMessage = this.getLocateMessage(entry, today);
    // Contentful has a `displayVotingLocationLookup` boolean
    // meant to toggle on/off the "Find out where to vote..." button
    // For use in IWV, we do care about this boolean,
    // but we also need to take into account whether
    // there is an active election for the jurisdiction.
    // An active election is used by /locate
    // to filter VIS' polling location response
    // TLDR: Even if the `displayVotingLocationLookup` boolean is set to `true`,
    // without an active election, the location finder will not display
    const lookupDisplayResult =
      pollingConfig.displayVotingLocationLookup.en && !!election;
    return {
      displayVotingLocationLookup: lookupDisplayResult,
      lookupExperience: lookupDisplayResult
        ? pollingConfig.locationLookupExperience.en
        : 'none',
      displayEarlyAbsenteeExcuse: pollingConfig.displayEarlyAbsenteeExcuse.en,
      earlyAbsenteeExcuseCopy: pollingConfig.earlyAbsenteeExcuseCopy,
      sosLookupUrl: pollingConfig.sosLocationLookupUrl,
      locateMessage,
    };
  }

  extractRegistrationConfig(
    entry: Option<ContentfulJurisdictionConfig>
  ): Option<JurisdictionRegistrationConfig> {
    const entryRegConfig = entry?.voterRegistrationConfig.en.fields;
    if (entryRegConfig) {
      return {
        registrationFlowEnabled:
          entryRegConfig.onlineinPersonRegistrationEnabled.en ||
          entryRegConfig.mailInpaperRegistrationEnabled.en,
        displayRegistrationLookup: entryRegConfig.displayRegistrationLookup.en,
        onlineInPerson: {
          enabled: entryRegConfig.onlineinPersonRegistrationEnabled.en,
          copy: entryRegConfig.onlineinPersonRegistrationCopy,
          onlineUrl: entryRegConfig.onlineinPersonRegistrationUrl,
          onlineButtonText: entryRegConfig.onlineinPersonRegistrationButtonText,
          alternateUrl: entryRegConfig.alternateRegistrationUrl,
          alternateButtonText: entryRegConfig.alternateRegistrationButtonText,
        },
        mailInPaper: {
          enabled: entryRegConfig.mailInpaperRegistrationEnabled.en,
          copy: entryRegConfig.mailInpaperRegistrationCopy,
          mailInPaperUrl: entryRegConfig.mailInpaperRegistrationUrl,
          mailInPaperButtonText:
            entryRegConfig.mailInpaperRegistrationButtonText,
        },
      };
    } else {
      return undefined;
    }
  }

  extractStateVepConfig(
    entry: Option<ContentfulJurisdictionConfig>
  ): Option<JurisdictionVepConfig> {
    if (!entry) {
      return undefined;
    }
    const dates = this.stateDeadlinesToImportantDate(entry);
    const vepConfigObject = entry?.vepConfig.en.fields;
    const datesBoolean = entry?.displayDatesDeadlines.en;
    return {
      jurisdictionCode: entry.stateCode.en,
      vepEnabled: vepConfigObject.vepEnabled.en,
      includeLocateOnVep: vepConfigObject.includeLocateOnVep.en,
      includeLocateInVepDates: vepConfigObject.includeLocateInVepDates.en,
      displayDates: datesBoolean,
      importantDatesAndDeadlines: dates,
      displayIdCopy: vepConfigObject.displayIdCopy.en,
      idRequirementsCopy: vepConfigObject.idRequirementsCopy,
      displayRegistrationCopy: vepConfigObject.displayRegistrationCopy.en,
      registrationRequirementsCopy:
        vepConfigObject.registrationRequirementsCopy,
      displayHowToCompleteBallotCopy:
        vepConfigObject.displayHowToCompleteBallotCopy.en,
      howToCompleteBallotCopy: vepConfigObject.howToCompleteBallotCopy,
    };
  }

  extractTerritoryVepConfig(
    entry: Option<ContentfulTerritoryConfig>
  ): Option<JurisdictionVepConfig> {
    if (!entry) {
      return undefined;
    }
    const dates = this.territoryDeadlinesToImportantDate(entry);
    const vepConfigObject = entry?.vepConfig.en.fields;
    const datesBoolean = entry?.displayDatesDeadlines.en;
    return {
      jurisdictionCode: entry.territoryCode.en,
      vepEnabled: vepConfigObject.vepEnabled.en,
      includeLocateOnVep: false,
      includeLocateInVepDates: false,
      displayDates: datesBoolean,
      importantDatesAndDeadlines: dates,
      displayIdCopy: vepConfigObject.displayIdCopy.en,
      idRequirementsCopy: vepConfigObject.idRequirementsCopy,
      displayRegistrationCopy: vepConfigObject.displayRegistrationCopy.en,
      registrationRequirementsCopy:
        vepConfigObject.registrationRequirementsCopy,
      displayHowToCompleteBallotCopy: false,
      howToCompleteBallotCopy: undefined,
    };
  }

  extractBallotRequestConfig(
    entry: Option<ContentfulJurisdictionConfig>
  ): Option<JurisdictionBallotRequestConfig> {
    if (!entry) {
      return undefined;
    }
    const ballotRequestConfigObj = entry.ballotRequestConfig?.en.fields;
    if (!ballotRequestConfigObj) {
      return undefined;
    }
    const ballotRequestExperience = ballotRequestConfigObj
      .ballotRequestFlowEnabled.en
      ? ballotRequestConfigObj.ballotRequestExperience.en
      : 'none';
    return {
      hasUniversalVBM: ballotRequestConfigObj.hasUniversalVbm.en,
      ballotRequestFlowEnabled:
        ballotRequestConfigObj.ballotRequestFlowEnabled.en,
      ballotRequestExperience: ballotRequestExperience,
      sosBallotRequestUrl: ballotRequestConfigObj.sosBallotRequestUrl,
      ballotRequestButtonText: ballotRequestConfigObj.ballotRequestButtonText,
    };
  }

  /**
   * Maps the language names from Contentful
   * in languageSupport field to their corresponding locales.
   * Falls back to {@link DEFAULT_LOCALES} if no entry is provided,
   * or if the field is not configured on the Contentful entry.
   *
   * 'languageSupport':
   *   - used to render <LocaleSelector/> in Site Language Menu
   *   - finalLocaleSupport for 'languageSupport' MUST include both 'en' and 'es'
   *     - if 'English' and 'Spanish' are not included in the languageSupport field,
   *       extractSupportedLocalesConfig() explicitly adds them
   */
  extractSupportedLocalesConfig(
    entry: Option<ContentfulJurisdictionConfig>
  ): JurisdictionLocaleSupport {
    if (!entry) {
      return DEFAULT_LOCALES;
    }
    const configuredLanguages = entry.languageSupport?.en;

    if (!configuredLanguages) {
      return DEFAULT_LOCALES;
    }

    const finalLocaleSupport =
      this.extractValidLocalesFromConfiguredLanguages(configuredLanguages);

    // ensure default locales are included in supportedLocales
    if (!finalLocaleSupport.includes(DefaultLanguageToLocale['English'])) {
      finalLocaleSupport.push('en');
    }
    if (!finalLocaleSupport.includes(DefaultLanguageToLocale['Spanish'])) {
      finalLocaleSupport.push('es');
    }
    // sort locales alphabetically
    return finalLocaleSupport.sort();
  }

  /**
   * Maps the language names from Contentful
   * in priorityLanguages field to their corresponding locales.
   * Falls back to {@link DEFAULT_LOCALES} if no entry is provided,
   * or if the field is not configured on the Contentful entry.
   * Also falls back to DEFAULT_LOCALES if Contentful's priority language options
   * are ahead of IWV {@link ActiveLanguage} definitions.
   *
   * 'priorityLanguages':
   *   - used for mobile layouts
   *   - maximum of two (logic resides in Contentful)
   *   - determines which <LocaleSelector/> has/have prioritized display
   *      - non-'priorityLanguages' within 'languageSupport' are displayed under a 'More ▼' dropdown
   */
  extractPriorityLocalesConfig(
    entry: Option<ContentfulJurisdictionConfig>
  ): JurisdictionLocaleSupport {
    if (!entry) {
      return DEFAULT_LOCALES;
    }
    const configuredLanguages = entry.priorityLanguages?.en;
    if (!configuredLanguages) {
      return DEFAULT_LOCALES;
    }

    const finalLocaleSupport =
      this.extractValidLocalesFromConfiguredLanguages(configuredLanguages);
    // if configured priorityLanguages are ahead of IWV's definitions
    // fallback to default locales
    if (finalLocaleSupport.length === 0) {
      return DEFAULT_LOCALES;
    }
    // sort locales alphabetically
    return finalLocaleSupport.sort();
  }

  /**
   * Validates language names from Contentful against IWV's definitions
   * of {@link ActiveLanguage}, in case Contentful's options for
   * languageSupport or priorityLanguages are ahead of existing IWV definitions.
   */
  extractValidLocalesFromConfiguredLanguages(
    configuredLanguages: string[]
  ): ActiveLocale[] {
    const validLocaleSupport: ActiveLocale[] = configuredLanguages
      .filter(isActiveLanguage)
      .map((activeLanguage) => ActiveLanguageToLocale[activeLanguage]);
    return [...validLocaleSupport];
  }

  extractNationalBooleanConfig(
    nationalEntries: ContentfulJurisdictionConfig[],
    today: moment.Moment
  ) {
    const nationalBooleanConfig = {} as NationalBooleanConfig;
    nationalEntries.forEach((entry) => {
      const stateCode = entry.stateCode.en as State;
      nationalBooleanConfig[stateCode] = this.extractJurisdictionBooleanConfig(
        entry,
        today
      );
    });
    return nationalBooleanConfig;
  }

  extractNationalLocalesConfig(
    nationalEntries: ContentfulJurisdictionConfig[]
  ) {
    const nationalLocalesConfig = {} as NationalLocalesConfig;
    nationalEntries.forEach((entry) => {
      const stateCode = entry.stateCode.en as State;
      nationalLocalesConfig[stateCode] = {
        supportedLocales: this.extractSupportedLocalesConfig(entry),
        priorityLocales: this.extractPriorityLocalesConfig(entry),
      };
    });
    return nationalLocalesConfig;
  }

  extractJurisdictionBooleanConfig(
    entry: ContentfulJurisdictionConfig,
    today: moment.Moment
  ) {
    const election = this.electionToElectionInfo(entry, today);
    const alert = this.extractAlert(entry);
    const pollingLocationLookupConfig = this.extractPollingLocationLookupConfig(
      entry,
      today,
      election
    );
    const registrationConfig = this.extractRegistrationConfig(entry);
    const voterEdPageConfig = this.extractStateVepConfig(entry);
    const ballotRequestConfig = this.extractBallotRequestConfig(entry);
    return {
      landingPage: {
        vepEnabled: !!voterEdPageConfig?.vepEnabled,
        displayVotingLocationLookup:
          pollingLocationLookupConfig.displayVotingLocationLookup,
        displayRegistrationLookup:
          pollingLocationLookupConfig.displayVotingLocationLookup,
        ballotRequestFlowEnabled:
          !!ballotRequestConfig?.ballotRequestFlowEnabled,
        hasActiveElection: !!election,
        hasActiveAlerts: !!alert,
      },
      vep: {
        displayDatesDeadlines: !!voterEdPageConfig?.displayDates,
        earlyVotingStartIsSameStatewide:
          entry.earlyVotingStartIsSameStatewide.en,
        includeLocateOnVep: !!voterEdPageConfig?.includeLocateOnVep,
        includeLocateInVepDates: !!voterEdPageConfig?.includeLocateInVepDates,
        displayIdCopy: !!voterEdPageConfig?.displayIdCopy,
        displayRegistrationCopy: !!voterEdPageConfig?.displayRegistrationCopy,
        displayHowToCompleteBallotCopy:
          !!voterEdPageConfig?.displayHowToCompleteBallotCopy,
      },
      locate: {
        displayVotingLocationLookup:
          pollingLocationLookupConfig.displayVotingLocationLookup,
        displayEarlyAbsenteeExcuse:
          pollingLocationLookupConfig.displayEarlyAbsenteeExcuse,
        isEarlyVotingAllowed: !!election?.earlyVoting.allowed,
        hasActiveElection: !!election,
        hasLocateOverrideMessage: !!pollingLocationLookupConfig.locateMessage,
        lookupExperience: pollingLocationLookupConfig.lookupExperience,
        sosLookupUrl: pollingLocationLookupConfig.sosLookupUrl,
      },
      ballotRequest: {
        hasUniversalVbm: !!ballotRequestConfig?.hasUniversalVBM,
        ballotRequestFlowEnabled:
          !!ballotRequestConfig?.ballotRequestFlowEnabled,
      },
      register: {
        registrationFlowEnabled: !!registrationConfig?.registrationFlowEnabled,
        onlineinPersonRegistrationEnabled:
          !!registrationConfig?.onlineInPerson.enabled,
        mailInpaperRegistrationEnabled:
          !!registrationConfig?.mailInPaper.enabled,
      },
    };
  }

  private createContentfulClient(isPreview: boolean) {
    const configuration = this.createContentfulConfig(isPreview);
    return createClient(configuration);
  }

  createContentfulConfig(isPreview: boolean) {
    const token = isPreview ? this.previewToken : this.displayToken;
    const hostUrl = isPreview ? this.previewUrl : this.displayUrl;
    return {
      accessToken: token,
      host: hostUrl,
      space: this.spaceId,
    };
  }

  async fetchJurisdictionConfig(
    stateCode: Option<Jurisdiction>,
    isPreview: boolean,
    useTestConfig: boolean,
    today: moment.Moment
  ): Promise<Option<JurisdictionConfig>> {
    /*
      Example documentation for requesting a single entry:
      Content Preview API: https://www.contentful.com/developers/docs/references/content-preview-api/#/reference/entries/entry/get-a-single-entry/console/js
      Content Delivery API: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/entries/entry/get-a-single-entry/console/js
      Adding search parameters to a query:
      Content Preview API: https://www.contentful.com/developers/docs/references/content-preview-api/#/reference/search-parameters
      Content Delivery API: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters
    */

    if (stateCode === undefined) {
      return undefined;
    }

    const client = this.createContentfulClient(isPreview);
    const entryId = useTestConfig
      ? TestingJurisdictionConfigEntries[stateCode as State]
      : ProductionJurisdictionConfigEntries[stateCode as State];

    /* 
      Instead of making a fetch request, we use Contentful's client to handle
      getting the jurisdiction config object.
      Using client.getEntry() targets a specific entry id,
      while client.getEntries() can broadly target a specific content type.
      getEntries() does accept query params, but in testing, it
      was not reliably filtering on stateCode.
      `include` and client.getEntry():
      `include` refers to linked references, specifically how many levels down
      to include in the response.
      
      New in contentful@10.0.0 : locale handling update
        Contentful introduced "client chaining methods"
        We call client.withAllLocales.getEntry() to request en/es
        localized content for a jurisdiction config object.
    */
    const entryResponse = await client.withAllLocales.getEntry(entryId, {
      include: 3,
    });
    const entry =
      (await entryResponse.fields) as Option<ContentfulJurisdictionConfig>;
    const election = this.electionToElectionInfo(entry, today);
    const alert = this.extractAlert(entry);
    const pollingLocationLookupConfig = this.extractPollingLocationLookupConfig(
      entry,
      today,
      election
    );
    const registrationConfig = this.extractRegistrationConfig(entry);
    const voterEdPageConfig = this.extractStateVepConfig(entry);
    const ballotRequestConfig = this.extractBallotRequestConfig(entry);
    const jurisdictionLocaleConfig = this.extractSupportedLocalesConfig(entry);
    const priorityLocaleConfig = this.extractPriorityLocalesConfig(entry);
    return {
      stateName: entry?.stateName.en,
      stateCode: entry?.stateCode.en,
      voterHotline: entry?.voterHotline?.en,
      supportedLocales: jurisdictionLocaleConfig,
      priorityLocales: priorityLocaleConfig,
      jurisdictionAlert: alert,
      electionInfo: election,
      pollingLocationLookupConfig: pollingLocationLookupConfig,
      registrationConfig: registrationConfig,
      vepConfig: voterEdPageConfig,
      ballotRequestConfig: ballotRequestConfig,
      updatedAt: parseDateString(entryResponse.sys.updatedAt).calendar(),
    };
  }

  async fetchTerritoryConfig(
    territoryCode: Option<Jurisdiction>,
    isPreview: boolean,
    useTestConfig: boolean
  ): Promise<Option<TerritoryConfig>> {
    if (territoryCode === undefined) {
      return undefined;
    }

    const client = this.createContentfulClient(isPreview);
    const entryId = useTestConfig
      ? TestingTerritoryConfigEntries[territoryCode as Territory]
      : ProductionTerritoryConfigEntries[territoryCode as Territory];

    const entryResponse = await client.withAllLocales.getEntry(entryId, {
      include: 3,
    });
    const entry =
      (await entryResponse.fields) as Option<ContentfulTerritoryConfig>;

    if (!entry) {
      return undefined;
    }
    const voterEdPageConfig = this.extractTerritoryVepConfig(entry);

    return {
      territoryName: entry?.territoryName.en,
      territoryCode: entry?.territoryCode.en,
      vepConfig: voterEdPageConfig,
    };
  }

  async fetchSitewideContent(
    isPreview: boolean
  ): Promise<Option<SitewideAlertsObject>> {
    /*
      This request targets the Sitewide Alerts content model
      (which is not specific to a jurisdiction, but the entire site)
      --the request query does not need to include a state code.
    */
    const client = this.createContentfulClient(isPreview);
    const contentId = 'sitewideAlerts';

    const entryResponse = await client.withAllLocales.getEntries({
      content_type: contentId,
      include: 3,
      limit: 1,
    });
    let alerts: LinkedAlertFields[] = [];
    if (entryResponse.includes?.Entry) {
      const contentfulEntry = await entryResponse.includes?.Entry;
      const linkedAlert = contentfulEntry[0]
        ?.fields as Option<ContentfulSitewideAlertsObject>;
      if (linkedAlert) {
        alerts = [linkedAlert];
      }
    }
    return { sitewideAlerts: alerts };
  }

  async fetchJurisdictionURLs(
    stateCode: Jurisdiction
  ): Promise<ExternalContentfulUrls> {
    const isPreview = false;
    const client = this.createContentfulClient(isPreview);
    const entryId = ProductionJurisdictionConfigEntries[stateCode as State];

    const entryResponse = await client.withAllLocales.getEntry(entryId, {
      include: 3,
    });
    const entry =
      (await entryResponse.fields) as Option<ContentfulJurisdictionConfig>;
    const jurisdictionURLs = getEntryUrls(entry);
    return jurisdictionURLs;
  }

  async fetchJurisdictionBooleanConfig(
    stateCode: Jurisdiction,
    isPreview: boolean,
    today: moment.Moment
  ): Promise<JurisdictionBooleanConfig> {
    const client = this.createContentfulClient(isPreview);
    const entryId = ProductionJurisdictionConfigEntries[stateCode as State];
    const entryResponse = await client.withAllLocales.getEntry(entryId, {
      include: 3,
    });
    const entry = (await entryResponse.fields) as ContentfulJurisdictionConfig;
    const jurisdictionBooleans = this.extractJurisdictionBooleanConfig(
      entry,
      today
    );
    return jurisdictionBooleans;
  }

  async fetchNationalConfigs(
    isPreview: boolean,
    today: moment.Moment
  ): Promise<[NationalBooleanConfig, NationalLocalesConfig]> {
    const client = this.createContentfulClient(isPreview);
    const productionEntryIds = Object.values(
      ProductionJurisdictionConfigEntries
    );
    const nationalResponse = await client.withAllLocales.getEntries({
      content_type: 'jurisdictionConfig',
      'sys.id[in]': productionEntryIds,
      include: 3,
    });
    const nationalEntries = await nationalResponse.items;
    const nationalEntryFields = nationalEntries.map(
      (entry) => entry.fields as ContentfulJurisdictionConfig
    );
    const nationalBooleans = this.extractNationalBooleanConfig(
      nationalEntryFields,
      today
    );
    const nationalLocales =
      this.extractNationalLocalesConfig(nationalEntryFields);
    return [nationalBooleans, nationalLocales];
  }
}
