import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import * as qs from 'query-string';
import React from 'react';
import { IntlProvider } from 'react-intl';

import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  ActiveLocale,
  DEFAULT_LOCALES,
  Jurisdiction,
  JurisdictionConfig,
  Jurisdictions,
  Option,
  defaultHostLocale,
  isActiveLocale,
} from '@dnc/baseline';

import {
  DEFAULT_LOCALE,
  DEFAULT_RICH_TEXT_ELEMENTS,
  locales,
} from '../../i18n/i18n-config';
import { Services } from '../../services/services';
import { UrlHelper } from '../../services/url-helper';
import { AnalyticsProvider } from '../AnalyticsProvider';
import { StateConfirmation } from '../StateConfirmation';
import { LocaleContext } from '../shared/LocaleContext';

import { Page } from './page';

/**
 * Context for {@link JURISDICTION_CONTEXT}, which is provided by
 * {@link ConnectedPage}.
 */
export type JurisdictionContext = {
  jurisdiction: Option<Jurisdiction>;
  jurisdictionConfig: Option<JurisdictionConfig>;
};

const JURISDICTION_CONTEXT = React.createContext<JurisdictionContext | null>(
  null
);

/**
 * Wrapper around {@link Page} that:
 *
 * - Sets up {@link LocaleProvider} with the locale from the URL.
 * - Sets up a {@link AnalyticsProvider} and passes it the props to keep it
 *   updated.
 * - Loads sitewide alerts via a react-query hook.
 * - Makes a {@link JURISDICTION_CONTEXT} `Provider`.
 * - Renders an {@link Outlet} as the {@link Page}’s child so it can be used in
 *   routes.
 */
export const ConnectedPage: React.FunctionComponent<
  {
    services: Services;

    locale: ActiveLocale;
    jurisdictionConfig: Option<JurisdictionConfig>;
  } & Omit<
    React.ComponentProps<typeof Page>,
    | 'children'
    | 'sitewideAlerts'
    | 'jurisdictionAlerts'
    | 'supportedLocales'
    | 'priorityLocales'
    | 'customVoterHotline'
  >
> = ({ services, locale, jurisdiction, jurisdictionConfig, ...props }) => {
  const location = useLocation();
  const urlHelper = UrlHelper.fromLocation(location);
  const contentfulParams = urlHelper.parseContentfulParams();

  // We load these via useQuery so that we don’t block first render on getting
  // them.
  const sitewideAlerts = useQuery(
    services.voterEducation.sitewideAlertsQueryOptions(
      !!contentfulParams.preview
    )
  );

  return (
    <LocaleContext.Provider value={locale}>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={locales[locale].messages}
        defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
      >
        <AnalyticsProvider
          analytics={services.analytics}
          location={location}
          locale={locale}
          jurisdiction={jurisdiction}
        >
          <Page
            jurisdiction={jurisdiction}
            sitewideAlerts={sitewideAlerts.data?.sitewideAlerts}
            jurisdictionAlerts={jurisdictionConfig?.jurisdictionAlert}
            supportedLocales={
              jurisdictionConfig?.supportedLocales ?? DEFAULT_LOCALES
            }
            priorityLocales={
              jurisdictionConfig?.priorityLocales ?? DEFAULT_LOCALES
            }
            customVoterHotline={jurisdictionConfig?.voterHotline}
            {...props}
          >
            <JURISDICTION_CONTEXT.Provider
              value={{ jurisdiction, jurisdictionConfig }}
            >
              <Outlet />
            </JURISDICTION_CONTEXT.Provider>
          </Page>
        </AnalyticsProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

/**
 * Returns the locale to use for this request.
 *
 * The `lang` query parameter takes precedence, but if it is not present this
 * falls back to the default host locale ('en' for IWillVote.com, 'es' for
 * VoyaVotar.com).
 *
 * If we have loaded the current jurisdiction’s config, will enforce that the
 * selected locale is within the allowed locales for it. If it’s not, will do a
 * redirect to remove the `lang` parameter.
 *
 * TODO(fiona): Add support for {@link defaultBrowserLocale}.
 */
function determineLocale(
  urlString: string,
  jurisdictionConfig: Option<JurisdictionConfig>
): ActiveLocale {
  const url = new URL(urlString);
  const queryParams = qs.parse(url.search);

  let locale: Option<ActiveLocale> = undefined;

  if (UrlHelper.LOCALE_PARAM in queryParams) {
    const localeFromParams = queryParams[UrlHelper.LOCALE_PARAM];
    const lowercasedLocaleFromParams = localeFromParams.toLowerCase();

    if (isActiveLocale(localeFromParams)) {
      locale = localeFromParams;
    } else if (isActiveLocale(lowercasedLocaleFromParams)) {
      locale = lowercasedLocaleFromParams;
    } else {
      // query parameter is not a locale we understand
      throw redirect(
        UrlHelper.changeRequestUrlParams(urlString, { lang: undefined })
      );
    }

    if (jurisdictionConfig) {
      if (!jurisdictionConfig.supportedLocales.includes(locale)) {
        // query parameter is a locale we understand, but it’s not valid for the
        // current jurisdiction, so remove it
        throw redirect(
          UrlHelper.changeRequestUrlParams(urlString, { lang: undefined })
        );
      }
    }
  }

  return locale ?? defaultHostLocale();
}

/**
 * Private helper for loading a {@link JurisdictionConfig}.
 *
 * Throws if the loading failed.
 */
async function fetchJurisdictionConfig(
  services: Services,
  url: string,
  jurisdiction: Jurisdiction
): Promise<JurisdictionConfig> {
  const today = moment();
  const urlHelper = UrlHelper.fromRequestUrl(url);
  const contentfulParams = urlHelper.parseContentfulParams();

  const jurisdictionConfig =
    await services.voterEducation.fetchJurisdictionConfig(
      jurisdiction,
      !!contentfulParams.preview,
      !!contentfulParams.test_config,
      today
    );

  if (!jurisdictionConfig) {
    throw new Error(`Failure loading jurisdiction: ${jurisdiction}`);
  }

  return jurisdictionConfig;
}

/**
 * Determines the jurisdiction and loads the jurisdictionConfig from the
 * "jurisdiction" path parameter.
 *
 * Used for the "/votinginfo/:jurisdiction" route.
 *
 * Redirects to '/' if the value of `:jurisdiction` isn’t a proper Jurisdiction,
 * errors if the {@link JurisdictionConfig} didn’t load, so this loader is
 * guaranteed to provide a value for both `jurisdiction` and
 * `jurisdictionConfig`.
 */
export async function loadJurisdictionFromPathParams(
  services: Services,
  { request, params }: LoaderFunctionArgs
): Promise<{
  locale: ActiveLocale;
  jurisdiction: Jurisdiction;
  jurisdictionConfig: JurisdictionConfig;
}> {
  const jurisdiction = params['jurisdiction']!;

  if (!Jurisdictions.isJurisdiction(jurisdiction)) {
    throw redirect('/');
  }

  const jurisdictionConfig = await fetchJurisdictionConfig(
    services,
    request.url,
    jurisdiction
  );

  return {
    locale: determineLocale(request.url, jurisdictionConfig),
    jurisdiction,
    jurisdictionConfig,
  };
}

/**
 * Determines the jurisdiction and loads the jurisdictionConfig from the "state"
 * query parameter. If it’s not provided, uses {@link JurisdictionService} to
 * look it up via geo-ip.
 *
 * If the value of the `state` parameter is not a valid jurisdiction, redirects
 * to the same URL, but without a `state` parameter.
 *
 * Respects a secret `no_geo` query parameter that prevents it from loading
 * geo-ip data, for easier testing of those cases.
 */
export async function loadJurisdictionFromSearchQuery(
  services: Services,
  { request }: LoaderFunctionArgs
): Promise<{
  locale: ActiveLocale;
  jurisdiction: Option<Jurisdiction>;
  jurisdictionConfig: Option<JurisdictionConfig>;
}> {
  const jurisdiction = await jurisdictionFromRequestParams(request, services);

  const jurisdictionConfig =
    jurisdiction &&
    (await fetchJurisdictionConfig(services, request.url, jurisdiction));

  return {
    locale: determineLocale(request.url, jurisdictionConfig),
    jurisdiction,
    jurisdictionConfig,
  };
}

/**
 * Returns either the "state" parameter from the query parameters, or uses geo
 * IP to look up the user’s state.
 *
 * Will throw a redirect object if `state` is provided but is not a valid state
 * code.
 */
export async function jurisdictionFromRequestParams(
  request: Request,
  services: Services
): Promise<Option<Jurisdiction>> {
  const url = new URL(request.url);
  const queryParams = qs.parse(url.search);

  if (queryParams[UrlHelper.JURISDICTION_PARAM]) {
    const stateParam = queryParams[UrlHelper.JURISDICTION_PARAM].toUpperCase();

    if (Jurisdictions.isJurisdiction(stateParam)) {
      return stateParam;
    } else {
      // "state" parameter is invalid, so remove it and redirect.
      throw redirect(
        UrlHelper.changeRequestUrlParams(request.url, { state: undefined })
      );
    }
  } else if (!queryParams[UrlHelper.NO_GEO_PARAM]) {
    // No state param, so try geo-ip
    return await services.jurisdiction.getJurisdiction();
  } else {
    return undefined;
  }
}

/**
 * Hook for {@link JURISDICTION_CONTEXT} that enforces that it has been
 * provided (likely by {@link ConnectedPage}).
 */
export function useJurisdictionContext(): JurisdictionContext {
  const ctx = React.useContext(JURISDICTION_CONTEXT);

  if (!ctx) {
    throw new Error(
      'Component must be mounted beneath a JurisdictionContext Provider'
    );
  }

  return ctx;
}

/**
 * Exposes the {@link Jurisdiction} and {@link JurisdictionConfig} from
 * {@link JURISDICTION_CONTEXT} / {@link ConnectedPage}.
 *
 * This gets them as-is, so they may be `undefined`.
 */
export const WithJurisdiction: React.FunctionComponent<{
  children: (props: JurisdictionContext) => React.ReactNode;
}> = ({ children }) => {
  const ctx = useJurisdictionContext();

  return children(ctx);
};

/**
 * Like {@link WithJurisdiction}, but for use in cases where the
 * {@link Jurisdiction} will never be `undefined`. (Such as it coming from the
 * route, like `/votinginfo/MA`.)
 *
 * @see loadJurisdictionFromPathParams
 */
export const WithKnownJurisdiction: React.FunctionComponent<{
  children: (props: {
    jurisdiction: Jurisdiction;
    jurisdictionConfig: Option<JurisdictionConfig>;
  }) => React.ReactNode;
}> = ({ children }) => {
  const { jurisdiction, jurisdictionConfig } = useJurisdictionContext();

  if (!jurisdiction) {
    throw new Error(
      'WithKnownJurisdiction must only be used when jurisdiction is never optional.'
    );
  }

  return children({ jurisdiction, jurisdictionConfig });
};

/**
 * Enforces that a jurisdiction has been selected and a config has been loaded.
 *
 * If the former is not true, renders a {@link StateConfirmation} instead of
 * children. If the latter is not true, renders a loading spinner.
 *
 * Otherwise it calls its child function with the `jurisdiction` and
 * `jurisdictionConfig`.
 *
 * When the user selects a jurisdiction via this component, the selection is set
 * by doing a history replace with the new jurisdiction in the `state` query
 * parameter.
 *
 * Note: currently our loaders do not support a known jurisdiction but a pending
 * jurisdictionConfig request, so the loading spinner won’t actually render. We
 * may change that in the future via `defer` in react-router.
 */
export const WithSelectedJurisdiction: React.FunctionComponent<{
  children: (props: {
    jurisdiction: Jurisdiction;
    jurisdictionConfig: JurisdictionConfig;
  }) => React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { jurisdiction, jurisdictionConfig } = useJurisdictionContext();

  if (!jurisdiction) {
    return (
      <StateConfirmation
        jurisdiction={jurisdiction}
        updateJurisdiction={(j) => {
          navigate(
            UrlHelper.changeLocationParams(location, {
              [UrlHelper.JURISDICTION_PARAM]: j,
            }),
            {
              replace: true,
              preventScrollReset: true,
            }
          );
        }}
      />
    );
  } else if (!jurisdictionConfig) {
    return <div className="loading-spinner" />;
  } else {
    return children({ jurisdiction, jurisdictionConfig });
  }
};
