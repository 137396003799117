/**
 * Awaits a given promise and returns its value.
 *
 * Calls the resolved and rejected handlers with the amount of time that elapsed
 * between when timedAwait called and when the promise either resolved or
 * rejected.
 */
export async function timedAwait<T>(
  p: Promise<T>,
  resolved?: ((millis: number) => unknown) | undefined,
  rejected?: ((millis: number) => unknown) | undefined
): Promise<T> {
  const start = Date.now();

  let result: T;

  try {
    result = await p;
  } catch (error) {
    // eslint-disable-next-line no-unused-expressions
    rejected?.(Date.now() - start);

    throw error;
  }

  // eslint-disable-next-line no-unused-expressions
  resolved?.(Date.now() - start);

  return result;
}
