import * as React from 'react';

import { FormattedMessage } from 'react-intl';

const year = new Date().getFullYear();

export const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col gap-4">
      <a
        href="https://www.fvap.gov/"
        target="_blank"
        className="abroad"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          defaultMessage="Voting While Living Abroad"
          id="footer.votingAbroad"
          tagName="span"
        />
      </a>
      <ul>
        <li>
          <a
            href="https://www.democrats.org/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              defaultMessage="Terms of Service"
              id="footer.tos"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.democrats.org/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              defaultMessage="Privacy Policy"
              id="footer.privacy"
            />
          </a>
        </li>
      </ul>
      <div>
        <FormattedMessage
          defaultMessage="Paid for by the Democratic National Committee {dncPhoneNumber}"
          id="footer.disclaimerHtml"
          values={{
            dncPhoneNumber: (
              <a href="tel:2028638000" className="no-wrap" dir="ltr">
                {'(202) 863-8000'}
              </a>
            ),
          }}
        />
        <br />
        <FormattedMessage
          defaultMessage="This communication is not authorized by any candidate or candidate’s committee."
          id="footer.notAuthorized"
        />
        <br />
        <p id="sms-disclaimer">
          <FormattedMessage
            defaultMessage="By texting ACCESS to 43367, you are consenting to receive recurring and/or automated text messages & calls from the DNC with news & info about our work. For SMS, message & data rates may apply. Text STOP to end. Text HELP for help."
            id="footer.access"
          />
        </p>
        <br />
        <FormattedMessage
          defaultMessage="Copyright © {year} DNC Services Corporation"
          id="footer.copyright"
          values={{
            year: year,
          }}
        />
      </div>
    </footer>
  );
};
