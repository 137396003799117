import moment from 'moment';

import { Jurisdiction } from '../data/jurisdictions';

import { VoterEducationService } from './voter-education-service';

export const ElectionErrorCodeIDs = [
  'NO_ACTIVE_ELECTION',
  'UNKNOWN_JURISDICTION',
];

export type ElectionErrorCode = (typeof ElectionErrorCodeIDs)[number];

export class NoElectionsError extends Error {
  jurisdiction: Jurisdiction | string;

  errorCode: ElectionErrorCode;

  constructor(
    jurisdiction: Jurisdiction | string,
    errorCode = 'NO_ACTIVE_ELECTION' as ElectionErrorCode
  ) {
    super(`No active election for ${jurisdiction}`);
    this.jurisdiction = jurisdiction;
    this.errorCode = errorCode;
    this.name = 'NoElectionsError';

    // Set the prototype explicitly.
    // See https://github.com/facebook/jest/issues/8279#issuecomment-539775425
    Object.setPrototypeOf(this, NoElectionsError.prototype);
  }
}

export class ElectionsService {
  // We use VoterEducationService because it has a cache.
  private readonly voterEducationService: VoterEducationService;

  constructor({
    voterEducationService,
  }: {
    voterEducationService: VoterEducationService;
  }) {
    this.voterEducationService = voterEducationService;
  }

  public async getNextElection(
    jurisdiction: Jurisdiction,
    isPreview: boolean,
    isNewConfig: boolean,
    today: moment.Moment
  ) {
    try {
      const jurisdictionInfo =
        this.voterEducationService.fetchJurisdictionConfig(
          jurisdiction,
          isPreview,
          isNewConfig,
          today
        );
      return (await jurisdictionInfo)?.electionInfo;
    } catch (e) {
      return undefined;
    }
  }
}

export type ElectionsProvider = {
  elections: ElectionsService;
};
